/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import moment from "moment";

export function formatMonth(date?: Date | string) {
    return moment(date).format('YYYY-MM')
}

export function formatDateTime(date?: Date | string) {
    return moment(date).format('YYYY-MM-DD HH:mm:ss')
}

export function formatDateTimeBegin(date?: Date | string) {
    return moment(date).format('YYYY-MM-DD 00:00:00')
}

export function formatDateTimeEnd(date?: Date | string) {
    return moment(date).format('YYYY-MM-DD 23:59:59')
}


export function formatDate(date?: Date | string) {
    return moment(date).format('YYYY-MM-DD')
}

export function lastWeekRange() {
    return [moment().subtract(7, 'days').format('YYYY-MM-DD 00:00:00'), moment().format('YYYY-MM-DD 23:59:59')]
}

export function nextWeekRange() {
    return [moment().format('YYYY-MM-DD 00:00:00'), moment().add(7, 'days').format('YYYY-MM-DD 23:59:59')]
}

export function lastNHourRange(n: number) {
    return [moment().subtract(n, 'hours').format('YYYY-MM-DD HH:mm:ss'), moment().format('YYYY-MM-DD HH:mm:ss')]
}

export function last24HourRange() {
    return [moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss'), moment().format('YYYY-MM-DD HH:mm:ss')]
}

export function todayRange() {
    return [moment().format('YYYY-MM-DD 00:00:00'), moment().format('YYYY-MM-DD 23:59:59')]
}

export function lastDayRange() {
    return [moment().subtract(1, 'days').format('YYYY-MM-DD 00:00:00'), moment().format('YYYY-MM-DD 23:59:59')]
}

export function lastDayRange2() {
    return [moment().subtract(1, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
}

export function lastNDayRange(n: number) {
    return [moment().subtract(n, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
}

export function lastMonthRange() {
    return [moment().subtract(1, 'months').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
}

export function monthRange(month: string) {
    let start = moment(month, 'YYYY-MM')
    let end = moment(month, 'YYYY-MM').endOf('month')
    if (moment().isBefore(end)) {
        end = moment()
    }
    return [start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD')]
}

export function dateTimeRangeHour(datetime: string, rangeHour: number) {
    let start = moment(datetime).subtract(rangeHour, 'hour').format('YYYY-MM-DD HH:mm:ss')
    let end = moment(datetime).add(rangeHour, 'hour').format('YYYY-MM-DD HH:mm:ss')
    return [start, end]
}
