/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { E_ChartType } from "@/utils/chart";
import { IPageData, IPageParams } from "../types";

export enum E_WeeklyReportSourceType {
    /**
     * 系统生成
     */
    SystemGenerate = 0,
    /**
     * 自己上传图片
     */
    Manual = 1,
    /**
     * 啥都没
     */
    None = 2,
}

/**
 * 设备状态
 * $t('enum.E_WeeklyReportEquipmentStatus.None')
 * $t('enum.E_WeeklyReportEquipmentStatus.Normal')
 * $t('enum.E_WeeklyReportEquipmentStatus.Alarm')
 * $t('enum.E_WeeklyReportEquipmentStatus.Halt')
 * $t('enum.E_WeeklyReportEquipmentStatus.Offline')
 */
export enum E_WeeklyReportEquipmentStatus {
    /**
     * 没有
     */
    None = 0,
    /**
     * 正常
     */
    Normal = 1,
    /**
     * 报警
     */
    Alarm = 2,
    /**
     * 停机
     */
    Halt = 3,
    /**
     * 离线
     */
    Offline = 4,
}

/**
 * 严重等级
 * $t('enum.E_WeeklyReportSeverityLevel.Normal')
 * $t('enum.E_WeeklyReportSeverityLevel.Minor')
 * $t('enum.E_WeeklyReportSeverityLevel.Severity')
 * $t('enum.E_WeeklyReportSeverityLevel.Offline')
 */
export enum E_WeeklyReportSeverityLevel {
    /**
     * 正常
     */
    Normal = 1,
    /**
     * 较严重
     */
    Minor = 2,
    /**
     * 严重
     */
    Severity = 3,
    /**
     * 离线
     */
    Offline = 4,
}

export interface IWithUploadImage {
    fileId: number
    fileUrl: string
    sourceType: E_WeeklyReportSourceType
}

export interface IWeeklyReportListItem {
    /**
     * 采集时间
     */
    collectTime: string
    /**
     * 公司Id
     */
    companyId: number
    /**
     * 公司名称
     */
    companyName: string
    id: number
    /**
     * 月周期
     */
    monthCycle: string
    /**
     * 总公司Id
     */
    parentCompanyId: number
    /**
     * 报告名称
     */
    reportName: string

    reportSn: string
}

export interface IWeeklyReportListPageParams extends IPageParams {
    companyId: string
}

export type IWeeklyReportListItemData = IPageData<IWeeklyReportListItem>

export interface IWeeklyReportHistoryPageParams {
    /**
     * 周报列表的ID
     */
    reportId: number
}

export interface IWeeklyReportHistoryPageItem {
    id: number
    createTime: string
    reportId: number
    reportName: string
    source: number
    updatedTime: string
    reportSn: string
}

export type IWeeklyReportHistoryPageData = IWeeklyReportHistoryPageItem[]

/**
 * 区域报警
 */
export interface IWeeklyReportAreaAlarmItem extends IWithUploadImage {
    apercentage?: number
    bpercentage?: number
    cpercentage?: number
    dpercentage?: number

    anum?: number
    bnum?: number
    cnum?: number
    dnum?: number

    areaId?: number
    areaName?: string
    companyId?: number
    companyName?: string
    createTime?: string
    updateTime?: string
    historyId?: number
    id?: number
    isDel?: number
    parentCompanyId?: number
    sort: number
    title: string
}

export interface IWeekWaveDetail {
    chartType: E_ChartType
    maps: {
        interval: number
        wave: {
            x: number
            y1: number
            y2: number
            y3: number
        }[]
    }
}

export interface IWeekWaveVO {
    waveId: number
    createTime: string
    mappingName: string
    /**
     * 传感器名称
     */    
    sensorId: string
    waveDetail: IWeekWaveDetail[]
}


/**
 * 设备统计
 */
export interface IWeeklyReportEquipRecordItem {
    areaId: number
    areaName: string
    companyId: number
    companyName: string
    createTime: string
    updateTime: string

    /**
     * 电流分析诊断，正常、报警、停机、离线
     */
    currentStatus: E_WeeklyReportEquipmentStatus

    /**
     * 波峰因素分析诊断
     */
    peakStatus: E_WeeklyReportEquipmentStatus

    /**
     * 峭度分析诊断
     */
    steepStatus: E_WeeklyReportEquipmentStatus

    /**
     * 温度分析诊断，正常、报警、停机、离线
     */
    temStatus: E_WeeklyReportEquipmentStatus
    /**
     * 振动分析诊断
     */
    vibrateStatus: E_WeeklyReportEquipmentStatus

    /**
     * 诊断结论
     */
    diagnosis: string

    equipmentId: number
    equipmentName: string

    /**
     * 图片Id集合
     */
    fileIdList: number[]

    /**
     * 上传的图片Id集合
     */
    fileIds: number[]

    /**
     * 
     */
    fileUrl: string[]

    /**
     * 健康评分
     */
    healthScore: number

    historyId: number
    id: number
    /**
     * 检测周期
     */
    monitorTime: string
    /**
     * 总公司Id
     */
    parentCompanyId: number
    /**
     * 故障分析结论
     */
    suggest: string
    /**
     * 严重等级
     */
    scoreStatus: E_WeeklyReportSeverityLevel
    /**
     * 标题
     */
    title: string
    /**
     * 报警波形Id集合
     */
    waveIds: string
    /**
     * 波形描述
     */
    waveTitle: string
    /**
     * 波形加速度和频谱数据(保存时候不要存)
     */
    weekWave?: IWeekWaveVO[]
    /**
     * 波形Id集合
     */
    waveIdList: any[]
}

export interface IMonitorWeekCoAlarm extends IWithUploadImage {
    apercentage: number
    bpercentage: number
    cpercentage: number
    dpercentage: number

    anum: number
    bnum: number
    cnum: number
    dnum: number

    companyId: number
    companyName: string

    createTime: string
    updateTime: string

    title: string
    id: number
    historyId: number
}

export interface IMonitorWeekCoDiagnosis extends IWithUploadImage {
    title: string
    companyId: number
    companyName: string
    historyId: number
    id: number
    /**
     * 
     */
    parentCompanyId: number
    /**
     * 轴承故障占比
     */
    bearingPercentage: number
    bearingNum: number
    /**
     * 风机叶片摩擦占比
     */
    fanPercentage: number
    fanNum: number
    /**
     * 齿轮箱不对中占比
     */
    gearBPercentage: number
    gearBNum: number
    /**
     * 齿轮箱负载高占比
     */
    gearFPercentage: number
    gearFNum: number
    /**
     * 齿轮箱磨损占比
     */
    gearMPercentage: number
    gearMNum: number
    /**
     * 不平衡占比
     */
    imbalancePercentage: number
    imbalanceNum: number
    /**
     * 设备基础松动占比
     */
    loosenessPercentage: number
    loosenessNum: number
    /**
     * 不对中占比
     */
    misalignedPercentage: number
    misalignedNum: number

    /**
     * 水泵叶片摩擦占比
     */
    pumpPercentage: number
    pumpNum: number
    /**
     * 共振占比
     */
    resonancePercentage: number
    resonanceNum: number
    /**
     * 转定子气息不均占比
     */
    unevenBreathPercentage: number
    unevenBreathNum: number

    createTime: string
    updateTime: string
}

export interface IWeeklyReportDetail {
    /**
     * 项目概述
     */
    overview: string
    /**
     * 
     */
    historyId: number
    /**
     * 周报Id
     */
    reportId: number
    /**
     * 周报名称
     */
    reportName: string

    /**
     * 区域报警
     */
    areaAlarms: IWeeklyReportAreaAlarmItem[]
    /**
     * 周期
     */
    cycle: string
    equipRecords: IWeeklyReportEquipRecordItem[]
    monitorWeekCoAlarm: IMonitorWeekCoAlarm
    /**
     * 有可能没有
     */
    monitorWeekCoDiagnosis?: IMonitorWeekCoDiagnosis
}

export interface IWeeklyReportDetailParams {
    /**
     * 周报历史Id
     */
    historyId: number
    /**
     * 周报
     */
    reportSn: string
}

export type IWeeklyReportEdit = IWeekWaveDetail

export interface IWeeklyReportExportPdfParams {
    reportSn: string
    historyId: number
    reportId: number
}
