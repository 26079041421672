/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import _ from 'lodash'
import { userCurrentInfo, userCurrentSysMenus } from '@/api/moudules/system'
import Vue from 'vue'
import Vuex from 'vuex'
import { E_IsSoonExpire, ICurrentUserInfo, IUserMenu } from '@/api/moudules/system.types'
import { getStorageValueWithTTL, storageValueWithTTL } from '@/utils/storage'
import { E_TimeUnit, toSeconds } from '@/utils/time'
import { E_YesNoNumber } from '@/api/types'
import { platformCompanyGetSetting } from '@/api/moudules/platformCompany'
import { IPlatformCompanySettingData } from '@/api/moudules/platformCompany.types'
import VueI18n from 'vue-i18n'
import { T_Lang } from '@/i18n/types'

Vue.use(Vuex)

function collectMenuPermissions(menus: IUserMenu[], permissions: string[]) {
  if (_.isEmpty(menus)) {
    return []
  }
  _.each(menus, menu => {
    permissions.push(menu.perms)
    collectMenuPermissions(menu.children, permissions)
  })
}

function defaultMenuRouterName(menus: IUserMenu[]) {
  if (_.isEmpty(menus)) {
    return ''
  }
  if (menus[0].path != '#') {
    return menus[0].path
  }
  return menus[0].children[0].path
}

function isSuperAdmin(acount: ICurrentUserInfo) {
    if(!acount) {
        return false    
    }
    return _.includes(acount.permissions, '*:*:*')
}

export function makeStore(i18n?: VueI18n) {
  const store = new Vuex.Store({
    state: {
      loading: false,
      account: null,
      menus: [],
      hasAlertSoonExpire: false,
      platformSetting: {} as IPlatformCompanySettingData
    },
    getters: {
      isSuperAdmin(state) {
        return isSuperAdmin(state.account)        
      },
      isAdmin(state) {        
        if (!state.account) {
          return false
        }
        return state.account.user?.superId === E_YesNoNumber.Yes
      },
      currentUserNickName(state) {
        return _.get(state, 'account.user.nickName', '')
      },
      defaultMenuRouterName(state) {
        return defaultMenuRouterName(state.menus)
      },
      defaultMainMenuRouterName(state) {
        return defaultMenuRouterName(_.filter(state.menus, m => m.path !== 'largeScreen'))
      },
      menuPermissionSet(state) {
        const permissions = []
        collectMenuPermissions(state.menus, permissions)
        return new Set(permissions)
      },      
      expireTime(state) {
        const account: ICurrentUserInfo = state.account || {}
        return account.expireTime
      },
      needAlertSoonExpire(state) {
        const account: ICurrentUserInfo = state.account || {}
        return account.isSoonExpire === E_IsSoonExpire.Alert && !state.hasAlertSoonExpire
      }
    },
    mutations: {
      updateLoading(state, loading: boolean) {
        state.loading = loading
      },
      updateAccount(state, account) {
        state.account = account
      },
      updateMenus(state, menus) {
        state.menus = menus
      },
      clearStates(state) {
        state.account = null
        state.menus = []
        state.platformSetting = {} as any        
      },
      setHasAlertSoonExpire(state, value: boolean) {
        state.hasAlertSoonExpire = value
      },
      markHasAlertSoonExpire(state) {
        state.hasAlertSoonExpire = true
        const key = buildAlertSoonExpirKey(state.account.user.id)
        storageValueWithTTL(key, 'true', toSeconds(24, E_TimeUnit.Hour))
      },
      updatePlatformSetting(state, value) {
        state.platformSetting = value
      },
    },
    actions: {
      async fetchAccountInfoWithMenu(context) {
        context.commit('updateLoading', true)
        try {
          const accountRes = await userCurrentInfo()
          context.commit('updateAccount', accountRes.data)
          const key = buildAlertSoonExpirKey(accountRes.data.user.id)
          context.commit('setHasAlertSoonExpire', getStorageValueWithTTL(key) || false)
          const menuRes = await userCurrentSysMenus()
          context.commit('updateMenus', menuRes.data)
          const userData = accountRes.data
          if(!isSuperAdmin(userData)) {            
            const platformSetting = await platformCompanyGetSetting({
                platformCompanyId: userData.user.parentCompanyId,
                languageType: (i18n?.locale || 'cn') as T_Lang
            })
            context.commit('updatePlatformSetting', platformSetting.data)
          }
        } finally {
          context.commit('updateLoading', false)
        }
      }
    },
  })
  return store
}

function buildAlertSoonExpirKey(userId) {
  return `alert_soon_expire__${userId}`
}
