/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import _ from 'lodash'
import { E_Axis, E_AxisType, E_CollectMode, E_MHFFlag, E_SensorDirection, E_SensorKlass, IAlarmTypeVO } from "@/api/moudules/sensor.types";
import {
    AlarmTypeOptions,
    AxisFullOptions,
    AxisSingleOptions,
    AxisThreeOptions,
    SensorDirectionOptions,
    SensorKlassTable
} from "@/constants/sensor";
import { toOptionPair } from '@/constants/util';
import { E_AlarmType } from '@/api/enums';
import { IOptionNumberItem } from '@/api/types';
import { E_TimeUnit, toMinute, toSeconds } from '@/utils/time';
import { DefualtAlarmConfigs, IAlarmConfig } from '@/constants/alarm';
import { nextWeekRange } from '@/utils/datetime';
import Vue from 'vue';
import { E_CarrierReversal, E_DriveType } from '@/api/moudules/sensorNew.types';

export function getAxisOptions(type: E_AxisType) {
    if (type === E_AxisType.Full) {
        return AxisFullOptions
    } else if (type === E_AxisType.Three) {
        return AxisThreeOptions
    } else {
        return AxisSingleOptions
    }
}

export function getSensorDirectionOptions(x: E_SensorDirection, y: E_SensorDirection, z: E_SensorDirection) {
    return {
        X: SensorDirectionOptions,
        Y: _.filter(SensorDirectionOptions, opt => {
            return opt != x
        }),
        Z: _.filter(SensorDirectionOptions, opt => {
            return opt != x && opt != y
        })
    }
}

export function hasPointOptions(type: E_SensorKlass) {
    const sensorKlass = _.find(SensorKlassTable, x => x.klass === type)
    if (!sensorKlass || !(sensorKlass.points || sensorKlass.pointsFn)) {
        return false
    }
    return true
}

export function getPointOptions(type: E_SensorKlass, kind: E_MHFFlag, alex: E_Axis, mhfHz: string) {
    const sensorKlass = _.find(SensorKlassTable, x => x.klass === type)
    if (!sensorKlass) {
        return []
    }
    const {
        points,
        pointsFn,
    } = sensorKlass
    let pointsUse = points
    if (pointsFn) {
        pointsUse = pointsFn(kind, alex, mhfHz)
    }
    return _.map(pointsUse, p => {
        return toOptionPair(p)
    })
}

/**
 * 获取传感器得所有频率列表 
 */
export function getAllMhfHzsBySensorType(sensorType: E_SensorKlass) {
    const sensor = _.find(SensorKlassTable, t => t.klass === sensorType)
    if (!sensor) {
        return []
    }
    const mhfHzs = _.flatMap(sensor.mhfHzs, s => {
        return s.options || []
    })
    return _.uniqBy(mhfHzs, x => x.value)
}

export namespace AlarmType {
    interface IAlarmTypeListForm {
        alarmTypeList: number[]
    }
    export function onSensorTypeChange(sensorType: E_SensorKlass, form: IAlarmTypeListForm) {
        if ([E_SensorKlass.VB11, E_SensorKlass.VBL11, E_SensorKlass.VBL12].includes(sensorType)) {
            form.alarmTypeList = _.filter(form.alarmTypeList, t => {
                return t !== E_AlarmType.Voltage
            })
        } else if (sensorType === E_SensorKlass.RVT50) {
            const validateOptions = new Set([
                E_AlarmType.Vibration,
                E_AlarmType.Temperature,
                E_AlarmType.Offline,
            ])
            form.alarmTypeList = _.filter(form.alarmTypeList, t => {
                return validateOptions.has(t)
            })
        }
    }

    export function onCollectModeChange(collectMode: E_CollectMode, form: IAlarmTypeListForm) {
        if (collectMode === E_CollectMode.Disable) {
            form.alarmTypeList = _.filter(form.alarmTypeList, t => {
                return t !== E_AlarmType.Steepness && t !== E_AlarmType.Crest
            })
        }
    }

    export function filterOptions(sensorType: E_SensorKlass, collectMode: E_CollectMode) {
        if ([E_SensorKlass.PT100, E_SensorKlass.GTS40A].includes(sensorType)) {
            return [E_AlarmType.Temperature, E_AlarmType.Offline]
        }
        if (sensorType === E_SensorKlass.VB24H) {
            return [
                E_AlarmType.Vibration,
                E_AlarmType.Envelope,
                E_AlarmType.HighFrequencyAcceleration,
                E_AlarmType.Temperature,
                E_AlarmType.Voltage,
            ]
        }
        let alarmTypeOptions = AlarmTypeOptions
        if (collectMode === E_CollectMode.Disable) {
            alarmTypeOptions = _.filter(alarmTypeOptions, t => {
                return t !== E_AlarmType.Steepness && t !== E_AlarmType.Crest
            })
        }
        if ([E_SensorKlass.VB11, E_SensorKlass.VBL11, E_SensorKlass.VBL12].includes(sensorType)) {
            alarmTypeOptions = _.filter(alarmTypeOptions, t => {
                return t !== E_AlarmType.Voltage
            })
        }
        if (sensorType === E_SensorKlass.RVT50) {
            const validateOptions = new Set([
                E_AlarmType.Vibration,
                E_AlarmType.Temperature,
                E_AlarmType.Offline,
            ])
            alarmTypeOptions = _.filter(alarmTypeOptions, t => {
                return validateOptions.has(t)
            })
        }
        return alarmTypeOptions
    }
    /**
     * 获取默认报警配置     
     */
    export function defaultAlarmConfig(alarmType: E_AlarmType) {
        return DefualtAlarmConfigs[alarmType]
    }
    //#region 报警配置
    export function mergeAlarmTypeConfig(config: IAlarmTypeVO[]) {
        return _.fromPairs(_.map(config, c => {
            const {
                alarmType,
                intervalCount,
                intervalTime,
            } = c
            return [alarmType, {
                intervalCount,
                intervalTime,
            }]
        }))
    }

    export function serializeAlarmTypeConfig(alarmTypeList: number[], alarmTypeConfig: Record<string, IAlarmConfig>) {
        return _.flatMap(alarmTypeList, alarmType => {
            const cfg = alarmTypeConfig[alarmType]
            if (cfg) {
                return {
                    alarmType: alarmType,
                    intervalCount: cfg.intervalCount,
                    intervalTime: cfg.intervalTime,
                }
            } else {
                return []
            }
        })
    }

    interface IVm extends Vue {
        showAlarmTypeConfig: boolean
        editAlarmType: E_AlarmType
        editAlarmTypeConfig: IAlarmConfig
    }

    interface IVmForm {
        alarmTypeConfig: Record<string, IAlarmConfig>
    }

    export function onAlarmTypeChange(vm: IVm, form: IVmForm, alarmType: E_AlarmType, value: boolean) {
        let defaultConfig = form.alarmTypeConfig[alarmType] || defaultAlarmConfig(alarmType)
        if (!defaultConfig) {
            return
        }
        if (value) {
            vm.$set(form.alarmTypeConfig, alarmType, _.cloneDeep(defaultConfig))
            vm.editAlarmType = alarmType
            vm.editAlarmTypeConfig = defaultConfig
            vm.showAlarmTypeConfig = true
        }
    }

    export function onAlarmTypeConfigClose(vm: IVm, form: IVmForm, success: boolean, config: IAlarmConfig) {
        vm.showAlarmTypeConfig = false
        if (success) {
            const alarmType = vm.editAlarmType
            form.alarmTypeConfig[alarmType].intervalCount = config.intervalCount
            form.alarmTypeConfig[alarmType].intervalTime = config.intervalTime
        }
    }
    //#endregion

}

export namespace SleepTAndXYZ {
    interface ISleepTAndXYZForm {
        sleepT: number
        x1: E_SensorDirection
        y1: E_SensorDirection
        z1: E_SensorDirection
    }
    export function onSensorTypeChange(sensorType: E_SensorKlass, form: ISleepTAndXYZForm) {
        if (sensorType === E_SensorKlass.RVT50) {
            form.sleepT = null
            form.x1 = null
            form.y1 = null
            form.z1 = null
        }
    }
}

export const EffectiveIntervalCounts = _.range(0, 10)

export const AlarmIntervals = (vm: Vue) => Object.freeze([
    {
        label: vm.$t('common.minutesTemplate', { value: 5 }),
        value: toMinute(5, E_TimeUnit.Minute),
    },
    {
        label: vm.$t('common.minutesTemplate', { value: 10 }),
        value: toMinute(10, E_TimeUnit.Minute),
    },
    {
        label: vm.$t('common.minutesTemplate', { value: 15 }),
        value: toMinute(15, E_TimeUnit.Minute),
    },
    {
        label: vm.$t('common.minutesTemplate', { value: 30 }),
        value: toMinute(30, E_TimeUnit.Minute),
    },
    {
        label: vm.$t('common.hourTemplate', { value: 1 }),
        value: toMinute(1, E_TimeUnit.Hour),
    },
    {
        label: vm.$t('common.hourTemplate', { value: 2 }),
        value: toMinute(2, E_TimeUnit.Hour),
    },
    {
        label: vm.$t('common.hourTemplate', { value: 3 }),
        value: toMinute(3, E_TimeUnit.Hour),
    },
    {
        label: vm.$t('common.hourTemplate', { value: 4 }),
        value: toMinute(4, E_TimeUnit.Hour),
    },

    {
        label: vm.$t('common.hourTemplate', { value: 5 }),
        value: toMinute(5, E_TimeUnit.Hour),
    },
    {
        label: vm.$t('common.hourTemplate', { value: 6 }),
        value: toMinute(6, E_TimeUnit.Hour),
    },
    {
        label: vm.$t('common.hourTemplate', { value: 7 }),
        value: toMinute(7, E_TimeUnit.Hour),
    },

    {
        label: vm.$t('common.hourTemplate', { value: 8 }),
        value: toMinute(8, E_TimeUnit.Hour),
    },
    {
        label: vm.$t('common.hourTemplate', { value: 9 }),
        value: toMinute(9, E_TimeUnit.Hour),
    },

    {
        label: vm.$t('common.hourTemplate', { value: 10 }),
        value: toMinute(10, E_TimeUnit.Hour),
    },
    {
        label: vm.$t('common.hourTemplate', { value: 12 }),
        value: toMinute(12, E_TimeUnit.Hour),
    },

    {
        label: vm.$t('common.hourTemplate', { value: 14 }),
        value: toMinute(14, E_TimeUnit.Hour),
    },
    {
        label: vm.$t('common.hourTemplate', { value: 16 }),
        value: toMinute(16, E_TimeUnit.Hour),
    },
    {
        label: vm.$t('common.hourTemplate', { value: 18 }),
        value: toMinute(18, E_TimeUnit.Hour),
    },
    {
        label: vm.$t('common.hourTemplate', { value: 20 }),
        value: toMinute(20, E_TimeUnit.Hour),
    },
    {
        label: vm.$t('common.hourTemplate', { value: 22 }),
        value: toMinute(22, E_TimeUnit.Hour),
    },
    {
        label: vm.$t('common.hourTemplate', { value: 24 }),
        value: toMinute(24, E_TimeUnit.Hour),
    },
])

export const AlarmIntervalCounts = Object.freeze(_.map(_.range(1, 15), x => {
    return {
        label: x,
        value: x,
    }
}))


export function hasAxialDisplacement(sensorKlass: E_SensorKlass) {
    let sensorFact = _.find(SensorKlassTable, t => t.klass === sensorKlass)
    if (!sensorFact) {
        return false
    }
    return Boolean(sensorFact.hasAxialDisplacement)
}

export function defaultLearnTime(learnStartTime?, learnEndTime?) {
    if (learnStartTime && learnEndTime) {
        return [learnStartTime, learnEndTime]
    }
    return nextWeekRange()
}

export function commonSensorRules(vm: Vue) {
    return {
        companyId: [
            { required: true, message: vm.$t('common.selectCompany'), trigger: 'change' },
        ],
        areaId: [
            { required: true, message: vm.$t('management.selectArea'), trigger: 'change' },
        ],
        equipmentId: [
            { required: true, message: vm.$t('management.selectEquipment'), trigger: 'change' },
        ],
        mappingId: [
            { required: true, message: vm.$t('management.selectMapping'), trigger: 'change' },
        ],
        sleepT: [
            { required: true, message: vm.$t('common.selectSleepTime'), trigger: 'change' },
        ],
        x1: [
            { required: true, message: vm.$t('common.selectXAxis'), trigger: 'change' },
        ],
        y1: [
            { required: true, message: vm.$t('common.selectYAxis'), trigger: 'change' },
        ],
        z1: [
            { required: true, message: vm.$t('common.selectZAxis'), trigger: 'change' },
        ],

        sensorSensitivity: [
            { required: true, message: vm.$t('management.inputSensorSensitivity'), trigger: 'blur' },
        ],

        alarmInterval: [
            { required: true, message: vm.$t('management.selectAlarmInterval'), trigger: 'change' },
        ],
        learnTime: [
            { required: true, message: vm.$t('equipment.pleaseSelectLearnTime'), trigger: 'change' },
        ],
        dataUpload: [
            { required: true, message: vm.$t('sensor.pleaseSelectDataUpload'), trigger: 'change' },
        ],
        dataSampling: [
            { required: true, message: vm.$t('sensor.pleaseSelectDataSampling'), trigger: 'change' },
        ],
        listening: [
            { required: true, message: vm.$t('sensor.pleaseSelectListening'), trigger: 'change' },
        ]
    }
}

export const DriveTypeOptions = Object.freeze([
    E_DriveType.DrivePort,
    E_DriveType.NonDrivePort,
])

export const CarrierReversalOptions = Object.freeze([
    E_CarrierReversal.NotReversal,
    E_CarrierReversal.Reversal,
])

/**
 * 扩频因子选项
 */
export const SpspOptions = Object.freeze([
    { label: '06', value: 6 },
    { label: '07', value: 7 },
    { label: '08', value: 8 },
    { label: '09', value: 9 },
    { label: '0A', value: 10 },
    { label: '0B', value: 11 },
    { label: '0C', value: 12 },
])