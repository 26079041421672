/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { ISamplingTimeData } from '@/api/moudules/wavefrom.types'
import { EChartImpl } from '@/components/EChart/EChartImpl'
import { E_Eigenvalue } from '@/types'
import { getAxialColor } from '@/utils/color'
import { spectrum } from '@/charts/waveChartOption'
import { formatTitle2 } from '@/view_helper/wave'
import * as echarts from 'echarts'
import _ from 'lodash'
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import ChartPopupOptions from '@/components/ChartPopupOptions.vue'

export interface IChartPanelOptions {
    chartKind: string
    chartOptions: {
        label: string,
        value: E_Eigenvalue
    }[]
}

@Component({
    name: 'ChartPanel',
    components: {
        ChartPopupOptions,
    }
})
export class ChartPanelImpl extends Vue {
    @Prop({
        type: Number,
    }) mappingId: number

    @Prop({
        type: Object,
    }) item: ISamplingTimeData

    @Prop({
        type: Object
    }) chartPanelOptions: IChartPanelOptions

    title = ''

    changeOption(value) {
        this.currentChart = value
        this.updateChart()
    }

    currentChart: E_Eigenvalue = null

    @Ref()
    chartRef: EChartImpl

    async requestFn(chart: echarts.ECharts) {
        const params = {
            mappingId: this.mappingId,
            chartType: this.currentChart,
            waveId: this.item.waveId
        }
        const res = await this.$api.wave.waveDetail(params)
        const { code, data } = res;
        if (code != 200 || null == data) {
            this.$message({
                message: this.$t('errors.noData') as string,
                type: 'success'
            });
            return;
        }
        this.title = formatTitle2(data.title)
        let option = spectrum(this, this.currentChart as any, data.wave, data.interval) as any        
        chart.setOption(option, true, true);
    }

    updateChart() {
        this.chartRef.request()
    }

    created() {
        this.currentChart = _.first(this.chartPanelOptions.chartOptions).value
    }
    mounted() {
        this.updateChart()
    }
}
