/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import _ from 'lodash'

export function reverse<T>(arr: T[]) {
    let res = _.clone(arr)
    _.reverse(res)
    return res
}

export function notNil(o) {
    return !_.isNil(o)
}

export function isEmptyStringOrNil(o) {
    if (_.isString(o)) {
        o = _.trim(o)
    }
    if (o === '') {
        return true
    }
    return _.isNil(o)
}

export function toNumberOrNull(o) {
    if (o === '' || o === null) {
        return null
    }
    let res = _.toNumber(o)
    if (!_.isNumber(res)) {
        return null
    }
    return res
}

export function maybeEmptyStringToNull(o) {
    if (o === '') {
        return null
    }
    return o
}

export function anyToBoolean(e) {
    if (_.isString(e)) {
        if (e.toLowerCase() === 'true') {
            return true
        }
        if (e.toLowerCase() === 'false') {
            return false
        }
    }
    return Boolean(e)
}

export function patchArrayRight<T>(array: T[], size: number, value = null) {
    array = array || []
    if (array.length >= size) {
        return array
    }
    return [...array, ...new Array(size - array.length).fill(value)]
}

export function groupToArrayBy<T, R>(arr: T[], fn: (item: T) => R) {
    let res: T[][] = []
    let lastItemGroup: T[] = []
    _.each(arr, item => {
        if (_.isEmpty(lastItemGroup)) {
            lastItemGroup.push(item)
        } else {
            if (fn(lastItemGroup[0]) === fn(item)) {
                lastItemGroup.push(item)
            } else {
                res.push(lastItemGroup)
                lastItemGroup = [item]
            }
        }
    })
    if (!_.isEmpty(lastItemGroup)) {
        res.push(lastItemGroup)
    }
    return res
}

export function groupToArrayWith<T, R>(arr: T[], fn: (acc: T[], item: T) => R) {
    let res: T[][] = []
    let lastItemGroup: T[] = []
    _.each(arr, item => {
        if (_.isEmpty(lastItemGroup)) {
            lastItemGroup.push(item)
        } else {
            if (fn(lastItemGroup, item)) {
                lastItemGroup.push(item)
            } else {
                res.push(lastItemGroup)
                lastItemGroup = [item]
            }
        }
    })
    if (!_.isEmpty(lastItemGroup)) {
        res.push(lastItemGroup)
    }
    return res
}
