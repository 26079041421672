/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { E_AlarmType } from "../enums";
import { E_YesNoNumber, IPageData, IPageParams } from "../types";

/**
 * $t('enum.E_StartStatus.Start')
 * $t('enum.E_StartStatus.Stop')
 */
export enum E_StartStatus {
    /**
     * 启动
     */
    Start = 1,
    /**
     * 关闭
     */
    Stop = 2
}

/**
 * 是否存在
 */
export enum E_Existed {
    /**
     * 不存在
     */
    NotExisted = 0,
    /**
     * 存在
     */
    Existed = 1,
}

export enum E_Axis {
    X = 'X',
    Y = 'Y',
    Z = 'Z',
    XYZ = 'XYZ',
}

export enum E_AxisType {
    /**
     * 单向只能选择Z
     */
    Single = 'Single',
    /**
     * 3向，选择XYZ
     */
    Three = 'Three',

    /**
     * 全部，选择X,Y,Z，XYZ
     */
    Full = 'Full',
}

/**
 * 启用波形采集
 * $t('enum.E_SensorOpenWave.Open')
 * $t('enum.E_SensorOpenWave.Close')
 */
export enum E_SensorOpenWave {
    /**
     * 开启
     */
    Open = 1,
    /**
     * 关闭
     */
    Close = 2,
}

/**
 * 传感器种类
 */
export enum E_SensorKlass {
    /**振动传感器列表 */
    VB11 = "VB11",
    VB12 = "VB12",
    VB21 = "VB21",
    VB22H = "VB22H",
    VB23P = "VB23P",
    VB24H = "VB24H",
    VB31 = "VB31",
    VB32 = "VB32",
    VB40 = "VB40",
    VB41P = "VB41P",
    VB42P = "VB42P",
    VB43 = 'VB43',
    VB50 = "VB50",
    VBE50 = "VBE50",
    VB60 = "VB60",
    VBL11 = "VBL11",
    VBL12 = "VBL12",
    VBL21 = "VBL21",
    VBS40 = "VBS40",
    VBS50H = "VBS50H",
    EVT40 = "EVT40",
    EVT41 = "EVT41",
    RVT50 = "RVT50",
    // 轴位移传感器
    GDS40 = 'GDS40',
    // /**电流传感器列表 */
    // GCS10 = "GCS10",
    // GCS20 = "GCS20",
    // GCS30 = "GCS30",
    // GCS40 = "GCS40",
    // GCS50 = "GCS50",
    // GCS60 = "GCS60",
    // /**温度传感器列表 */
    // GTS10 = "GTS10",
    // GTS20 = "GTS20",
    // GTS30 = "GTS30",
    // GTS40 = "GTS40",
    GTS40A = "GTS40-A",
    // GTS50 = "GTS50",
    // GTS60 = "GTS60",    
    // /**液位传感器列表 */
    // GLS20SA = "GLS20SA",
    // GLS20SB = "GLS20SB",
    // GLS40SA = "GLS40SA",
    // GLS40SB = "GLS40SB",
    // GLS50SA = "GLS50SA",
    // GLS50SB = "GLS50SB",

    /**
     * 温度传感器
     */
    PT100 = 'PT100',

    /**
     * 用于采集卡
     */
    CollectCard_Temperature = "CollectCard_Temperature",
    CollectCard_RotateSpeed = "CollectCard_RotateSpeed",
    CollectCard_Vibration = "CollectCard_Vibration",

    //#region 新余钢铁    
    /**
     * 转速传感器
     */
    XYGT_RPM = 'XYGT-RPM',
    /**
     * 轴位移传感器
     */
    XYGT_AD = 'XYGT-AD',
    /**
     * 温度传感器
     */
    XYGT_TEMP = 'XYGT-TEMP',
    //#endregion
}


/**
 * 采样频率选择
 * $t('enum.E_MHFFlag.None')
 * $t('enum.E_MHFFlag.Middle')
 * $t('enum.E_MHFFlag.High')
 * $t('enum.E_MHFFlag.LongWave')
 */
export enum E_MHFFlag {
    /**
     * 无
     */
    None = -1,
    /**
     * 中频
     */
    Middle = 0,
    /**
     * 高频
     */
    High = 1,
    /**
     * 长波形
     */
    LongWave = 2,
}

/**
 * 工作方式
 * $t('enum.E_CollectWork.Normal')
 * $t('enum.E_CollectWork.Timebase')
 */
export enum E_CollectWork {
    /**
     * 普通模式
     */
    Normal = 1,
    /**
     * 时间管理模式
     */
    Timebase = 2,
}

/**
 * 采集模型
 * $t('enum.E_CollectMode.Disable')
 * $t('enum.E_CollectMode.EveryTime')
 * $t('enum.E_CollectMode.Interval')
 * $t('enum.E_CollectMode.Daily')
 * $t('enum.E_CollectMode.Every3Hour')
 * $t('enum.E_CollectMode.Every6Hour')
 * $t('enum.E_CollectMode.Every12Hour')
 * $t('enum.E_CollectMode.Every24Hour')
 */
export enum E_CollectMode {
    /**
     * 不自动采集
     */
    Disable = 0,
    /**
     * 每次自动采集
     */
    EveryTime = 1,
    /**
     * 间隔自动采集
     */
    Interval = 2,
    /**
     * 每天采集
     */
    Daily = 3,
    /**
     * 3小时采集一次
     */
    Every3Hour = 4,
    /**
     * 6小时采集一次
     */
    Every6Hour = 5,
    /**
     * 12小时采集一次
     */
    Every12Hour = 6,
    /**
     * 24小时采集一次
     */
    Every24Hour = 7,
}

/**
 * $t('enum.E_SensorDirection.Horizontal')
 * $t('enum.E_SensorDirection.Axial')
 * $t('enum.E_SensorDirection.Vertical')
 */
export enum E_SensorDirection {
    /**
     * 水平
     */
    Horizontal = 1,
    /**
     * 轴向
     */
    Axial = 2,
    /**
     * 垂直
     */
    Vertical = 3,
}

/**
 * 安装方式
 * $t('enum.E_InstallMethod.MAGNETISM')
 * $t('enum.E_InstallMethod.BOLET')
 */
export enum E_InstallMethod {
    /**
     * 磁座安装
     */
    MAGNETISM = 'MAGNETISM',
    /**
     * 螺栓安装
     */
    BOLET = 'BOLET',
}

/**
 * $t('enum.E_SensorType.TemperatureVibration')
 * $t('enum.E_SensorType.Temperature')
 * $t('enum.E_SensorType.Vibration')
 * $t('enum.E_SensorType.Pressure')
 * $t('enum.E_SensorType.Current')
 * $t('enum.E_SensorType.LiquidLevel')
 */
export enum E_SensorType {
    /**
     * 温振
     */
    TemperatureVibration = 1,
    /**
     * 温度
     */
    Temperature = 2,
    /**
     * 振动
     */
    Vibration = 3,
    /**
     * 压力
     */
    Pressure = 4,
    /**
     * 电流
     */
    Current = 5,
    /**
     * 液位
     */
    LiquidLevel = 6,
}

export interface ISensorItem {
    mappingId: number
    /**
     * 测点名称
     */
    pointName: string
    /**
     * 传感器编号
     */
    sensorId: string
    /**
     * 设备id
     */
    equipmentId: number
    /**
     * 设备名称
     */
    equipmentName: string
    /**
     * 区域id
     */
    areaId
    /**
     * 区域名称
     */
    areaName
    /**
     * 公司id
     */
    companyId
    /**
     * 公司名称
     */
    companyName
    /**
     * 
     */
    id: number
    /**
     * 传感器名称
     */
    name: string
    /**
     * 传感器类型
     */
    type: string
    /**
     * 采集模型
     */
    collectMode: E_CollectMode
    /**
     * 报警标准
     */
    thresholdName: string
}

export interface ISensorListPageParams extends IPageParams {
    companyId?: number
    areaId?: number
    equipmentIdList?: number[]
    id?: number
}

export type ISensorListPageData = IPageData<ISensorItem>

export interface ISensorSetItem {
    /**
     * 中频，高频标志位
     */
    mhfFlag?: E_MHFFlag
    /**
     * 点数
     */
    wavePoint?: number
    /**
     * 轴向:X, Y, Z, XYZ',
     */
    axis?: E_Axis
    /**
     * 采样频率
     */
    mhfHz?: string

    mhfUse: E_YesNoNumber

    /**
     * 传感器灵敏度
     */
    sensorSensitivity: number
    /**
     * 偏置电压
     */
    voltage: number

    /**
     * 采集间隔
     */
    samplingInterval?: number
}

export interface IAlarmTypeVO {
    /**
     * 报警类型
     */
    alarmType: E_AlarmType
    /**
     * 报警间隔次数
     */
    intervalCount: number
    /**
     * 报警间隔时间，分钟
     */
    intervalTime: number
}

export interface ISensorVB24H {
    /**
     * VB24H特征值上传数
     */
    dataCount?: number
    /**
     * VB24H特征值采样时间
     */
    dataSampling?: number
    /**
     * VB24H特征值上传时间
     */
    dataUpload?: number
    /**
     * VB24H硬件版本号
     */
    hardwareVersion?: string
    /**
     * VB24H侦听时长
     */
    listening?: number
    /**
     * VB24H启用波形采集，1开启，2关闭
     */
    openWave?: E_SensorOpenWave
    /**
     * VB24H软件版本号
     */
    softwareVersion?: string
    /**
     * VB24H上传率
     */
    uploadRate?: string

    /**
     * VB24H高频Z轴启停
     */
    highZSs?: number

    /**
     * VB24H中频Z轴启停
     */
    lowZSs?: number

    /**
     * VB24H传感器类型
     */
    sensorType?: E_SensorType

    /**
     * VB24H信号强度
     */
    signalHeart?: number

    /**
     * VB24H无限通道
     */
    wirelessChannel?: number
    /**
     * VB24H无线频段
     */
    wirelessFrequency?: string
}

/**
 * Sensor
 */
export interface ICreateEditSensorItem extends ISensorVB24H {
    /**
     * 传感器编号
     */
    id: string;
    /**
    * 传感器名称
    */
    name: string;
    /**
     * 传感器类型
     */
    type: E_SensorKlass;
    /**
     * 公司ID
     */
    companyId: number
    /**
     * 区域ID
     */
    areaId: number;
    /**
     * 设备ID
     */
    equipmentId: number

    /**
     * 测点ID
     */
    mappingId: string

    /**
     * 网关ID
     */
    gatewayId: number

    /**
     * 阈值ID
     */
    alarmThresholdId: number

    /**
     * 报警类型, 逗号分隔
     * E_AlarmType[]
     */
    alarmTypeList: string
    /**
     * 工作方式
     */
    collectWork: E_CollectWork
    /**
     * 报警时是否采集
     */
    alarmCollect: E_YesNoNumber

    /**
     * 0:不自动采集、1:每次自动采集、2：间隔自动采集、3:每天采集
     */
    collectMode: E_CollectMode;

    /**
     * 每天采集时间
     */
    collectTime: number

    /**
     * 采集间隔
     */
    collectSpace: number

    /**
     * 1:启用故障自诊断
     */
    isFault: E_YesNoNumber;
    /**
     * 
     */
    sleepT: number;

    /**
     * 1:水平，2：径向，3：垂直
     */
    x1: E_SensorDirection
    y1: E_SensorDirection
    z1: E_SensorDirection

    /**
     * 频率数组
     */
    sensorSet: ISensorSetItem[]

    fileArray?: { id: number, url: string }[]

    /**
     * 上传的图片数组
     */
    fileIdArray?: number[]

    /**
     * 获取具体信息时候
     */
    fileUrls?: string[]
    fileIds?: string

    /**
     * 安装方式
     */
    installMode: E_InstallMethod

    /**
     * 传感器灵敏度
     */
    sensorSensitivity?: number

    /**
     * 偏置电压
     */
    voltage?: number

    /**
     * 报警间隔
     * 单位(分钟)
     */
    alarmInterval: number

    /**
     * 每个报警类型加入不同参数
     */
    alarmTypeVOS?: IAlarmTypeVO[]

    /**
     * 启动ai自学习
     */
    openLearn?: E_StartStatus
    /**
     * ai自学习结束时间
     */
    learnEndTime?: string
    /**
     * ai自学习开始时间
     */
    learnStartTime?: string
}

/**
 * AlarmThreshold
 */
export interface AlarmThreshold {
    battCMax: number;
    battDMax: number;
    id: string;
    kurtXAMax: number;
    kurtXBMax: number;
    kurtXCMax: number;
    kurtXDMax: number;
    kurtYAMax: number;
    kurtYBMax: number;
    kurtYCMax: number;
    kurtYDMax: number;
    kurtZAMax: number;
    kurtZBMax: number;
    kurtZCMax: number;
    kurtZDMax: number;
    peakXAMax: number;
    peakXBMax: number;
    peakXCMax: number;
    peakXDMax: number;
    peakYAMax: number;
    peakYBMax: number;
    peakYCMax: number;
    peakYDMax: number;
    peakZAMax: number;
    peakZBMax: number;
    peakZCMax: number;
    peakZDMax: number;
    temAMax: number;
    temBMax: number;
    temCMax: number;
    temDMax: number;
    vXAMax: number;
    vXBMax: number;
    vXCMax: number;
    vXDMax: number;
    vYAMax: number;
    vYBMax: number;
    vYCMax: number;
    vYDMax: number;
    vZAMax: number;
    vZBMax: number;
    vZCMax: number;
    vZDMax: number;
}
export type ICreateSensorParams = ICreateEditSensorItem



export type IEditSensorParams = ICreateEditSensorItem & {
    /**
     * 旧的传感器编号
     */
    sensorIdOld: string
}

export interface IGetSensorData extends ISensorVB24H {
    alarmCollect: number;
    alarmLevel: number;
    alarmThreshold: null;
    alarmThresholdId: number;
    alarmTypeList: string;
    areaId: number;
    collectWork: E_CollectWork
    collectMode: number;
    collectorID: null;
    collectSpace: number;
    collectTime: number;
    companyId: number;
    createTime: string;
    delFlag: number;
    equipmentId: null;
    expand: null;
    gatewayId: number;
    gateWayName: string;
    id: string;
    isFault: number;
    isReadParam1: number;
    isReadParam2: number;
    isReadParam3: number;
    isReadParam4: number;
    lastCollectTime: number;
    lastTime: null;
    mac: null;
    mappingId: null;
    mhfFlag: number;
    name: string;
    sensorSet: ISensorSetItem[];
    sleepT: number;
    type: E_SensorKlass;
    updateTime: string;
    x1: number;
    y1: number;
    z1: number;
    fileIdArray: number[]
    fileUrls: string[]
    installMode: E_InstallMethod
    wavePoint: string
    mhfHz: string

    /**
     * 传感器灵敏度
     */
    sensorSensitivity: number

    /**
     * 偏置电压
     */
    voltage: number
    /**
     * 单位(分钟)
     */
    alarmInterval: number
}
